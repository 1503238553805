<template>
  <template v-for="item in detailData.list" :key="item.id">
    <div class="detailData">
      <ul>
        <li>게임ID</li>
        <li>{{ item["_id"].$oid }}</li>
      </ul>
      <ul>
        <li>타이틀</li>
        <li>{{ item["title"] }}</li>
      </ul>
      <ul>
        <li>베팅금액</li>
        <li>{{ item["bet"] }}</li>
      </ul>
      <ul>
        <li>당첨금액</li>
        <li>{{ item["win"] }}</li>
      </ul>
      <ul>
        <li>{{ $t('searchArea.endDate') }}</li>
        <li>{{ item["result_time"] }}</li>
      </ul>
      <ul>
        <li>결과</li>
        <li>Win : {{ item["result"].res.winner }}<br>
          플레이어 : {{ item["result"].res.playerScore }}, 뱅커 : {{ item["result"].res.bankerScore }}<br>
          플-페어 : {{ item["result"].res.playerPair }}, 뱅-페어 : {{ item["result"].res.bankerPair }}<br>
          내추럴 : {{ item["result"].res.natural }}
        </li>
      </ul>
    </div>
    <p class="dataname">베팅 및 게임결과</p>
    <div class="detailData" v-if="item.result">
      <ul>
        <li>베팅위치</li>
        <li>{{ item["betes"] }}</li>
      </ul>
      <ul v-if="item.result && item.result.details">
        <li>뱅커 : {{item.result.details.bankerHand.score}}</li>
        <li class="data">
          <div class="card">
            <em :class="'C' + item['result'].details.bankerHand.cards[0]">{{ item["result"].details.bankerHand.cards[0] }}</em>
            <em :class="'C' + item['result'].details.bankerHand.cards[1]">{{ item["result"].details.bankerHand.cards[1] }}</em>
            <em :class="'C' + item['result'].details.bankerHand.cards[2]">{{ item["result"].details.bankerHand.cards[2] }}</em>
          </div>
        </li>
      </ul>
      <ul v-if="item.result && item.result.details">
        <li>플레이어 : {{ item.result.details.playerHand.score }}</li>
        <li class="data">
          <div class="card">
             <em :class="'C' + item['result'].details.playerHand.cards[0]">{{ item["result"].details.playerHand.cards[0] }}</em>
             <em :class="'C' + item['result'].details.playerHand.cards[1]">{{ item["result"].details.playerHand.cards[1] }}</em>
             <em :class="'C' + item['result'].details.playerHand.cards[2]">{{ item["result"].details.playerHand.cards[2] }}</em>
          </div>
        </li>
      </ul>
      <!--<ul>
        <li>전체데이터</li>
        <li>{{ item }}</li>
      </ul>-->
    </div>
  </template>
</template>

<script>
export default {
  name: 'vendorCode1',
  props: ['detailData']
}
</script>

<style scoped></style>
<script setup></script>
